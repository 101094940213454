export const options = {
  loop: {
    controls: false,
    autostart: true,
    displaydescription: false,
    displaytitle: false,
    autostart: true,
    repeat: true,
    mute: true
  },
  multimedia: {},
  default: {}
}