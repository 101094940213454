import { options } from "@/shared/JWPlayer/config";
import { Video, ContentVideo} from "./styles";
import { useEffect, useRef } from "react";
const VideoLoop = ({videoLoop = "",  titleLocation = "",}) => {
const nameCustomEvent = "jwPlayerScriptLoaded";
const idJwPlayer = "jwPlayerLibraryvideo_home";
const videoRef = useRef();
const entityIDdiv= "loop"+videoLoop?.entityId
  const installPlayerScript = () => {
    const jwPlayerScript = document.createElement("script");
    jwPlayerScript.id = idJwPlayer;
    jwPlayerScript.src = process.env.NEXT_PUBLIC_HOME_JWPLAYER_ID;
    jwPlayerScript.async = true;
    jwPlayerScript.onload = () => {
      document.dispatchEvent(new CustomEvent(nameCustomEvent));
      initialize();
    };
    document.body.appendChild(jwPlayerScript);
  };


  const initialize = () => {
    window.removeEventListener("load", initialize);
    const player = window.jwplayer(videoRef.current);
    const optionsPlayer = options.loop ?? {};
    
    const playerOpts = {
      ...(videoLoop.url 
          ? { file: videoLoop.url } 
          : { playlist: `https://cdn.jwplayer.com/v2/media/${videoLoop.entityId}` }),
      image: "",
      ...optionsPlayer
    };

    if (videoRef.current) {
      player.setup(playerOpts);     
    }
  };

  useEffect(() => {
    const existingLibraryScript = document.getElementById(idJwPlayer);
    if (!existingLibraryScript) {
      installPlayerScript();
    } else if (window?.jwplayer) {
      initialize();
    } else {
      document.addEventListener(nameCustomEvent, initialize, { once: true });
    }    
  }, []);

  if(!entityIDdiv){
    return false;
  }
 
  return (
    <ContentVideo className={` ${titleLocation} contentVideo`}> 
        <div id={entityIDdiv} ref={videoRef}/>
        {videoLoop?.videoName &&
          <p className="epigraphe">
            {videoLoop?.videoName}
          </p>
        }
    </ContentVideo>
  );
};

export default VideoLoop;
